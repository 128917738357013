export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/404.html": [3],
		"/alternate-reality/[slug]": [6],
		"/future/[slug]": [7],
		"/hidden-links": [8],
		"/playground/app-review-links": [9],
		"/playground/audio": [10],
		"/playground/bigeditabletext": [11],
		"/playground/blabla": [12],
		"/playground/box-timer": [13],
		"/playground/chrono": [14],
		"/playground/dev-tools": [15],
		"/playground/eliza": [16],
		"/playground/falling-money": [17],
		"/playground/jwt": [18],
		"/playground/poll": [19],
		"/playground/simple-module-example": [20],
		"/playground/time-boxing": [21],
		"/playground/typing-train": [22],
		"/playground/web-components": [23],
		"/privacy-policy": [24],
		"/vdi": [25],
		"/[slug]": [4],
		"/[year]/[month]/[day]": [5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';